// ----------------------------------------------------------------------

export function remToPx(value) {
  return Math.round(parseFloat(value) * 16)
}

export function pxToRem(value) {
  return `${value / 16}rem`
}

export function responsiveFontSizes({ xs, sm, md, lg, xl }) {
  return {
    '@media (min-width:600px)': {
      fontSize: pxToRem(sm)
    },
    '@media (min-width:900px)': {
      fontSize: pxToRem(md)
    },
    '@media (min-width:1200px)': {
      fontSize: pxToRem(lg)
    },
    '@media (min-width:1536px)': {
      fontSize: pxToRem(xl)
    }
  }
}

// ----------------------------------------------------------------------

const FONT_PRIMARY = 'Helvetica Neue LT Pro, sans-serif' // Google Font
// const FONT_SECONDARY = 'CircularStd, sans-serif'; // Local Font

const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  h1: {
    fontWeight: 500,
    lineHeight: 1.25,
    fontSize: pxToRem(72),
    ...responsiveFontSizes({ sm: 48, md: 62, lg: 62, xl: 72 })
  },
  h2: {
    fontWeight: 500,
    lineHeight: '96px',
    fontSize: pxToRem(80),
    ...responsiveFontSizes({ sm: 43, md: 57, lg: 80 })
  },
  h3: {
    fontWeight: 500,
    lineHeight: 1.25,
    fontSize: pxToRem(56),
    ...responsiveFontSizes({ sm: 30, md: 30, lg: 40, xl: 56 })
  },
  h4: {
    fontWeight: 500,
    lineHeight: '56px',
    fontSize: pxToRem(48),
    ...responsiveFontSizes({ sm: 26, md: 34, lg: 48 })
  },
  h5: {
    fontWeight: 500,
    lineHeight: 40,
    fontSize: pxToRem(35),
    ...responsiveFontSizes({ sm: 19, md: 25, lg: 35 })
  },
  h6: {
    fontWeight: 500,
    lineHeight: '32px',
    fontSize: pxToRem(27),
    ...responsiveFontSizes({ sm: 15, md: 19, lg: 27 })
  },
  quote: {
    fontWeight: 500,
    lineHeight: '52px',
    fontSize: pxToRem(45),
    ...responsiveFontSizes({ sm: 24, md: 32, lg: 45 })
  },
  large: {
    fontWeight: 500,
    lineHeight: '40px',
    fontSize: pxToRem(35),
    ...responsiveFontSizes({ sm: 19, md: 25, lg: 35 })
  },
  title: {
    fontWeight: 500,
    lineHeight: '29px',
    fontSize: pxToRem(24),
    ...responsiveFontSizes({ sm: 18, md: 20, lg: 24 })
  },
  body24: {
    fontWeight: 500,
    lineHeight: '29px',
    fontSize: pxToRem(24),
    ...responsiveFontSizes({ sm: 16, md: 18, lg: 20, xl: 24 })
  },
  body20: {
    fontWeight: 500,
    lineHeight: '26px',
    fontSize: pxToRem(20),
    ...responsiveFontSizes({ sm: 16, md: 16, lg: 18, xl: 20 })
  },
  button: {
    fontWeight: 500,
    lineHeight: 1,
    fontSize: pxToRem(16)
  },
  body18: {
    fontWeight: 500,
    lineHeight: '23px',
    fontSize: pxToRem(18),
    ...responsiveFontSizes({ sm: 16, md: 16, lg: 18, xl: 18 })
  },
  body16: {
    fontWeight: 500,
    lineHeight: '24px',
    fontSize: pxToRem(16),
    ...responsiveFontSizes({ sm: 16, md: 16, lg: 16 })
  },
  body14: {
    fontWeight: 500,
    lineHeight: '16px',
    fontSize: pxToRem(14)
  },
  body12: {
    fontWeight: 500,
    lineHeight: '16px',
    fontSize: pxToRem(12)
  }
}

export default typography
