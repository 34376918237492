import { styled } from '@mui/material/styles'
import { Outlet } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { currentHubState } from 'src/recoil/hub/atoms'

import Header from './components/header'
import Navbar from './components/navbar'

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
})

const Main = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  position: 'relative',
  flexGrow: 1,
  minHeight: '100%',
  backgroundColor: '#F5F5F5'
}))

export default function BaseLayout() {
  const currentHub = useRecoilValue(currentHubState)

  return (
    <StyledRoot>
      {currentHub && <Navbar />}

      <Main>
        <Header />
        <Outlet />
      </Main>
    </StyledRoot>
  )
}
