import _ from 'lodash'
import { getRecoil, setRecoil } from 'recoil-nexus'
import HttpStatusCode from 'src/constants/HttpStatusCode'
import { hubListStateSelector } from 'src/recoil/hub/selectors'

import { getListDeviceAPI } from '../api/DeviceApi'
import {
  deleteHubAPI,
  getActivitiesLogAPI,
  getHubList,
  restartSmartHubAPI
} from '../api/HubApi'
import {
  currentHubDetailState,
  currentHubState,
  hubListState
} from '../recoil/hub/atoms'
import { resetCurrentHubAction } from './SmarthubAction'

export const fetchHubListAction = async () => {
  const listHub = await getHubList().then((response) => {
    if (_.isEqual(response.statusCode, HttpStatusCode.NotFound)) {
      return []
    }

    return _.get(response, 'data.items')
  })

  setRecoil(hubListState, listHub)
}

export const restartSmartHub = async (id) => {
  const currentHub = getRecoil(currentHubState)
  let smartHubId = currentHub

  if (id) {
    smartHubId = id
  }

  return restartSmartHubAPI(smartHubId).then((data) => {
    return data
  })
}

export const removeCurrentSmartHub = async () => {
  const currentHub = getRecoil(currentHubState)
  return deleteHubAPI(currentHub).then((data) => {
    resetCurrentHubAction()
    return data
  })
}

export const fetchHubList = async () => {
  const listHub = await getHubList().then((response) => {
    if (!_.isEqual(response.statusCode, HttpStatusCode.Ok)) {
      return []
    }

    return _.get(response, 'data.items')
  })

  const listDeviceResponse = await getListDeviceAPI()

  let listDevice = []

  if (
    listDeviceResponse &&
    _.isEqual(listDeviceResponse.statusCode, HttpStatusCode.Ok)
  ) {
    listDevice = _.get(listDeviceResponse, 'data.items')
  }

  const currentHub = getRecoil(currentHubState)

  const hubDetail = listHub.find((hub) => hub.id === currentHub)

  setRecoil(hubListState, listHub)
  setRecoil(currentHubDetailState, { ...hubDetail, devices: listDevice.length })
}

export const checkDuplicateHubName = ({ id, name }) => {
  const listHub = getRecoil(hubListState)

  const isDuplicate = listHub.find(
    (item) =>
      item.name.trim().toLowerCase() === name.trim().toLowerCase() &&
      id !== item.id
  )

  return !!isDuplicate
}

export const checkDuplicateFieldHub = (id, value, field) => {
  const listHub = getRecoil(hubListStateSelector)

  if (_.isEmpty(listHub)) {
    return false
  }

  const isDuplicate = listHub.find(
    (item) =>
      item[field].trim().toLowerCase() === value.trim().toLowerCase() &&
      id !== item.id
  )

  return !!isDuplicate
}

const fetchHubActivitiesLog = async (id) => {
  const ListActivitiesLog = await getActivitiesLogAPI(id).then((response) => {
    if (!_.isEqual(response.statusCode, HttpStatusCode.Ok)) {
      return []
    }

    return _.get(response, 'data.items')
  })
  console.log(ListActivitiesLog)
}

export default {
  fetchHubActivitiesLog
}
