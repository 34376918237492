// routes
import dayjs from 'dayjs'
import advanced from 'dayjs/plugin/advancedFormat'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { ConfirmProvider } from 'material-ui-confirm'
import { Suspense } from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'

import Loading from '../src/components/loading'
import PageUrl from './constants/PageUrl'
// components
import './global.css'
import { currentHubState } from './recoil/hub/atoms'
import Router from './routes'
import { connectSocket } from './services/socket'
// theme
import ThemeProvider from './theme'

// ----------------------------------------------------------------------

dayjs.extend(relativeTime)
dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.extend(advanced)
dayjs.extend(isSameOrAfter)

export default function App() {
  const navigate = useNavigate()

  const currentHubId = useRecoilValue(currentHubState)

  useEffect(() => {
    let websocket = null
    websocket = connectSocket()
    websocket.on(`smarthub_remove`, (data) => {
      const { smart_hub_id } = data

      if (smart_hub_id == currentHubId) {
        navigate(PageUrl.SMARTHUB_SELECTION)
      }
    })
    websocket.on('connect', () => {})

    return () => {
      if (websocket) {
        websocket.disconnect()
      }
    }
  }, [])

  return (
    <ConfirmProvider>
      <ThemeProvider>
        <Suspense fallback={<Loading />}>
          <Router />
        </Suspense>
      </ThemeProvider>
    </ConfirmProvider>
  )
}
