import { MenuItem as MUIMenuItem } from '@mui/material'
import * as _ from 'lodash'

export default function MenuItem(props) {
  const _defaultProps = {
    sx: {
      fontFamily: 'inherit',
      '&.Mui-selected': {
        backgroundColor: 'rgba(255, 255, 255, 0.50)',
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.50)'
        }
      }
    }
  }

  const { children, ...restProps } = _.merge(_defaultProps, props)

  return <MUIMenuItem {...restProps}>{children}</MUIMenuItem>
}
