import SvgColor from '../svg-color'

export default function SmartHubIcon(props) {
  const { sx } = props

  return (
    <SvgColor
      src="/assets/icons/ic_smart_hub.svg"
      sx={{ width: 33, height: 33, ...sx }}
      {...props}
    />
  )
}
