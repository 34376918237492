import {Box, FormControlLabel} from '@mui/material'
import * as _ from 'lodash'
import AccountPopover from './AccountPopover'
import SelectHub from './SelectHub'
import Switch from '../../../../components/inputs/switch'
import React, {useEffect, useState} from 'react'
import {useNavigate, useLocation} from 'react-router-dom'
import demoConfig from '../../../../config/demo.config'

export default function RightHeader(props) {
    const {currentHub, hubList} = props
    const navigate = useNavigate()
    const location = useLocation()
    const demoConfigFunc = demoConfig()
    const _func = {
        _isDemoMode: () => {
            let checked = false;
            let isHasDemo = _.findKey(demoConfigFunc, {
                'path': location.pathname
            })
            let urlDemo = `/demo${location.pathname}`
            if (_.includes(location.pathname, '/demo/')) {
                isHasDemo = true;
                checked = true;
                urlDemo = _.replace(location.pathname, '/demo/', '/')
            }
            return (!_.isUndefined(isHasDemo) &&
                <FormControlLabel value="top" sx={{justifyContent: 'end'}} control={<Switch
                    checked={checked}
                    sx={{
                        marginRight: 1
                    }}
                    onChange={() => {
                        navigate(urlDemo)
                    }}
                />} label="Demo Mode"/>)
        }
    }
    return (
        <Box
            sx={{
                display: 'flex',
                gap: 2,
                alignItems: 'center'
            }}>
            {_func._isDemoMode()}
            {currentHub && <SelectHub hubList={hubList}/>}

            <AccountPopover/>
        </Box>
    )
}
