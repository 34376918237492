import * as _ from 'lodash'
import { selector } from 'recoil'

import { getHubList } from '../../api/HubApi'
import HttpStatusCode from '../../constants/HttpStatusCode'
import { hubListState } from './atoms'

export const hubListStateSelector = selector({
  key: 'HubList_Selector',
  get: async ({ get }) => {
    let hubList = get(hubListState)

    if (!_.isEmpty(hubList)) {
      return hubList
    }

    const response = await getHubList()

    if (!_.isEqual(response.statusCode, HttpStatusCode.Ok)) {
      return hubList
    }

    return _.get(response, 'data.items')
  }
})
