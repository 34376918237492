import {useAuth0} from '@auth0/auth0-react'
import {Box} from '@mui/material'
import _ from 'lodash'
import {useLocation} from 'react-router-dom'
import {useRecoilValue, useSetRecoilState} from 'recoil'
import {currentHubState, hubListState} from 'src/recoil/hub/atoms'
import {hubListStateSelector} from 'src/recoil/hub/selectors'

import {HIDE_IN_PATH} from '../../../constants'
import LogoSection from './LogoSection'
import RightHeader from './right-header'
import {useEffect, useState} from "react";
import {connectSocket} from "../../../services/socket";
import {fetchHubList} from "../../../recoil-action/HubAction";

export default function Header() {
    const {isAuthenticated} = useAuth0()

    const currentHub = useRecoilValue(currentHubState)
    const hubList = useRecoilValue(hubListStateSelector)
    useEffect(() => {
        let websocket = null
        websocket = connectSocket()
        websocket.on(`smarthub_refresh_list`, (data) => {
            fetchHubList()
        })
        websocket.on('connect', () => {
            console.log('connected')
        })

        return () => {
            if (websocket) {
                websocket.disconnect()
            }
        }
    }, [])

    // const hideRightHeader = HIDE_IN_PATH.includes(location.pathname)
    return (
        <Box
            sx={{
                width: '100%',
                position: 'fixed',
                top: 0,
                right: 0,
                zIndex: 1100,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                pl: currentHub ? 10 : 2,
                pr: 2,
                pt: '12px',
                pb: '12px'
            }}>
            <LogoSection/>

            {isAuthenticated && (
                // && !hideRightHeader
                <RightHeader currentHub={currentHub} hubList={hubList}/>
            )}
        </Box>
    )
}
