export const AUTH_TOKEN = 'auth_token'
export const CURRENT_HUB = 'current_hub'
export const HIDE_IN_PATH = ['/account-not-found', '/smarthub/not-connection']

export const STATUS_ENUM = {
  ACTIVATE: 1,
  DEACTIVATE: 0,
  DELETE: -1
}

export const STATUS_MAPPING = {
  [STATUS_ENUM.ACTIVATE]: 'Activate',
  [STATUS_ENUM.DEACTIVATE]: 'Deactivate',
  [STATUS_ENUM.DELETE]: 'Delete'
}

export const CARD_TYPE_ENUM = {
  WHEN: 'when',
  IF: 'if',
  THEN: 'then'
}

export const CARD_TYPE = [
  CARD_TYPE_ENUM.WHEN,
  CARD_TYPE_ENUM.IF,
  CARD_TYPE_ENUM.THEN
]

export const TEXT_SIZE_TYPE = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large'
}

export const BACKGROUND_TYPE = {
  COLOR: 'color',
  IMAGE: 'image'
}

export const DASHBOARD_EDITOR_OBJECT_TYPE = {
  DASHBOARD: 'dashboard',
  COMPONENT: 'component'
}

export const DASHBOARD_EDITOR_TYPE = {
  COLOR: 'color',
  BACKGROUND: 'background',
  TEXT_SIZE: 'textSize',
  ICON: 'iconUrl',
  STATUS: 'status',
  RESET: 'reset'
}

export const TRANS = {
  VALIDATION: 'validation',
  TRANSLATION: 'translation',
  SMARTHUB: 'smarthub',
  LOGIN: 'login',
  DASHBOARD: 'dashboard',
  DEVICE: 'device',
  PLUGIN: 'plugin',
  FLOW: 'flow',
  FOLDER_FLOW: 'folder-flow',
  CARD: 'card',
  ZONE: 'zone'
}

export const ZONE_ENUM = {
  UNASSIGNED_DEVICES: 'unassigned_devices',
  ALL: 'all'
}
