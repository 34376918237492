import {FormControl} from '@mui/material'
import * as _ from 'lodash'
import {useLocation, useNavigate} from 'react-router-dom'
import {useRecoilState, useRecoilValue} from 'recoil'
import SmartHubStatus from 'src/constants/SmartHubStatus'

import Select from '../../../../components/inputs/select'
import MenuItem from '../../../../components/navigation/menu-item'
import {CURRENT_HUB} from '../../../../constants'
import {currentHubState} from '../../../../recoil/hub/atoms'
import {hubListStateSelector} from '../../../../recoil/hub/selectors'
import PageUrl from '../../../../constants/PageUrl'

export default function SelectHub(props) {
    const {hubList} = props

    const navigate = useNavigate();
    const location = useLocation();
    const [currentHub, setCurrentHub] = useRecoilState(currentHubState)

    const onChange = ({target: {value}}) => {
        setCurrentHub(value)
        localStorage.setItem(CURRENT_HUB, value)
        if (_.isEqual(location.pathname, PageUrl.SMARTHUB_REGISTRATION) ||
            _.isEqual(location.pathname, PageUrl.SMARTHUB_DEMO(PageUrl.SMARTHUB_REGISTRATION))) {
            navigate(PageUrl.FLOW);
        } else {
            navigate(0)
        }

    }

    return (
        <FormControl
            sx={{
                width: '220px'
            }}>
            <Select
                sx={{
                    height: 36,
                    fontWeight: '500',
                    fontSize: '16px',
                    backgroundColor: 'background.white',
                    filter: 'drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.15))',
                    borderRadius: 'unset',
                    '& svg': {
                        right: '16px'
                    },
                    '& .MuiSelect-select.MuiOutlinedInput-input': {
                        pl: '16px',
                        pr: hubList.length > 1 ? '38px' : '16px'
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderWidth: {
                            sm: '1px',
                            xl: '1.25px'
                        }
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderWidth: {
                            sm: '1px',
                            xl: '1.25px'
                        }
                    }
                }}
                value={currentHub}
                onChange={onChange}>
                {!_.isEmpty(hubList) &&
                    hubList.map((hub) => (
                        <MenuItem
                            key={_.get(hub, 'id')}
                            sx={{
                                p: '12px 16px',
                                fontWeight: '500',
                                fontSize: '16px',
                                lineHeight: 1,
                                '&:hover': {
                                    backgroundColor: 'background.grey'
                                },
                                '&.Mui-selected': {
                                    color: 'common.primary',
                                    backgroundColor: 'background.grey',
                                    '&:hover': {
                                        backgroundColor: 'background.grey'
                                    }
                                }
                            }}
                            value={_.get(hub, 'id')}
                            disabled={
                                !_.isEqual(
                                    _.get(hub, 'currentStatus'),
                                    SmartHubStatus.ACTIVE
                                )
                            }>
                            {_.get(hub, 'name')}
                        </MenuItem>
                    ))}
            </Select>
        </FormControl>
    )
}
