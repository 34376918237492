import SvgColor from '../components/svg-color'

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/${name}.svg`}
    sx={{ backgroundColor: 'white' }}
  />
)

function NavConfig(RUNTIME_ENV) {
  return {
    NAVBAR: [
      {
        title: 'dashboard',
        path: '/dashboard',
        icon: icon('ic_view_quilt')
      },
      {
        title: 'flow',
        path: '/my-flow',
        icon: icon('ic_flow')
      },
      {
        title: 'home',
        path: '/my-zone',
        icon: icon('ic_home')
      },
      {
        title: 'hub',
        path: '/my-hub',
        icon: icon('ic_hub'),
        divider: true
      },
      {
        title: 'scan devices',
        path: '/device/scans',
        icon: icon('ic_scan_device')
      },
      {
        title: 'lab',
        path: '/my-lab',
        icon: icon('ic_lab')
      }
    ]
  }
}

export default NavConfig
