function AppConfig(RUNTIME_ENV) {
  return {
    DOMAIN: RUNTIME_ENV.REACT_APP_DOMAIN,
    DOMAIN_API: RUNTIME_ENV.REACT_APP_DOMAIN_API,
    DOMAIN_API_MERGING: RUNTIME_ENV.REACT_APP_DOMAIN_API_MERGING,
    REDIRECT_LOGIN: '/login',
    REDIRECT_AFTER_LOGIN: '/dashboard',
    SOCKET_URL: RUNTIME_ENV.REACT_APP_SOCKET_URL,
    WEBSITE: {
      LOGO: ''
    },
    TIME_DURATION: [
      {
        name: 'Past 30 minutes',
        value: '30m'
      },
      {
        name: 'Past hour',
        value: '1h'
      },
      {
        name: 'Past 2h hours',
        value: '2h'
      },
      {
        name: 'Past 6h hours',
        value: '6h'
      },
      {
        name: 'Past 24 hours',
        value: '1d'
      },
      {
        name: 'Past week',
        value: '1w'
      }
    ],
    TIME_ZONE_ENUM: {
      UTC: 'utc',
      LOCAL: 'local'
    },
    TIME_ZONE: [
      {
        name: 'UTC',
        value: 'utc'
      },
      {
        name: 'Local',
        value: 'local'
      }
    ],
    LIMIT_RECORDS: [50, 100, 200, 500, 1000]
  }
}

export default AppConfig
