import _ from 'lodash'
import { atom } from 'recoil'
import { selector } from 'recoil'
import { getFlowListAPI } from 'src/api/FlowApi'
import { getListFolderFlowByHubIdAPI } from 'src/api/FolderFlowApi'
import HttpStatusCode from 'src/constants/HttpStatusCode'
import { customSortName } from 'src/utils/array'

import { listFolderFlowState } from '../folder-flow/folderFlowState'

export const listFlowState = atom({
  key: 'list_flow',
  default: []
})

export const listFlowSelector = selector({
  key: 'list_flow_selector',
  get: async ({ get }) => {
    let listFlow = get(listFlowState)

    if (_.isEmpty(listFlow)) {
      const folderFlowResponse = await getListFolderFlowByHubIdAPI()
      const flowResponse = await getFlowListAPI()

      if (
        !_.isUndefined(flowResponse.statusCode) &&
        _.isEqual(flowResponse.statusCode, HttpStatusCode.Ok)
      ) {
        listFlow = _.get(flowResponse, 'data.items', [])

        if (!_.isEmpty(listFlow)) {
          listFlow = listFlow.sort((a, b) => {
            return customSortName(a.name, b.name)
          })
        }
      }

      if (
        !_.isUndefined(folderFlowResponse.statusCode) &&
        _.isEqual(folderFlowResponse.statusCode, HttpStatusCode.Ok)
      ) {
        const listFolderFlow = _.get(folderFlowResponse, 'data.items', [])

        if (!_.isEmpty(listFolderFlow)) {
          listFolderFlow = listFolderFlow.sort((a, b) => {
            return customSortName(a.name, b.name)
          })

          listFolderFlow = listFolderFlow.map((folderFlow) => {
            const foundedFlows = listFlow.filter(
              (flow) => flow.folderId === folderFlow.id
            )

            if (!_.isEmpty(foundedFlows)) {
              folderFlow.hasFlow = true
              folderFlow.flows = foundedFlows
            } else {
              folderFlow.hasFlow = false
              folderFlow.flows = []
            }

            return folderFlow
          })
        }
      }
    }

    return listFlow
  }
})
