import Config from '../config'
import { CURRENT_HUB } from '../constants'
import { httpDelete, httpGet, httpPost } from '../services/http'

const urlMerging = `${Config.DOMAIN_API_MERGING}`
const endPoints = {
  list: `/_api/folder-flow/list`,
  create: `/_api/folder-flow/create`,
  update: (id) => `/_api/folder-flow/${id}/edit`,
  delete: (id) => `/_api/folder-flow/${id}`,
  deleteFlow: (id) => `/_api/folder-flow/${id}/flow`
}

export const getListFolderFlowByHubIdAPI = () => {
  const hubId = localStorage.getItem(CURRENT_HUB)
  return httpGet(
    `${urlMerging}${endPoints.list}`,
    { smart_hub_id: hubId },
    {},
    true
  )
}

export const createFolderFlowAPI = (data = {}) => {
  const hubId = localStorage.getItem(CURRENT_HUB)
  data.smartHubId = hubId

  return httpPost(`${urlMerging}${endPoints.create}`, data, {}, true)
}

export const updateFolderFlowAPI = (id, data = {}) => {
  return httpPost(`${urlMerging}${endPoints.update(id)}`, data, {}, true)
}

export const deleteFolderFlowAPI = (id) => {
  return httpDelete(`${urlMerging}${endPoints.delete(id)}`, {}, {}, true)
}

export const deleteFlowFromFolderAPI = (folderId, flowId) => {
  return httpDelete(
    `${urlMerging}${endPoints.deleteFlow(folderId)}`,
    {
      flowId
    },
    {},
    true
  )
}
