import _ from 'lodash'
import { TRANS } from 'src/constants'

const { useTranslation } = require('react-i18next')

const _useTranslation = (ns, options = {}) => {
  if (_.isArray(ns)) {
    ns = [TRANS.TRANSLATION, ...ns]
  } else if (_.isString(ns)) {
    ns = [TRANS.TRANSLATION, ns]
  }

  const { t, ...rest } = useTranslation(ns, options)

  const _t = (key, ns, options = {}) => {
    if (!_.isUndefined(ns)) {
      if (_.isString(ns)) {
        options = _.merge(options, { ns })
      }

      if (_.isObject(ns)) {
        options = _.merge(options, ns)
      }
    }

    return t(key, options)
  }

  return { t: _t, ...rest }
}

export default _useTranslation
