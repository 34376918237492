import { Box } from '@mui/material'
import { NavLink as RouterLink } from 'react-router-dom'

export default function LogoSection() {
  return (
    <Box
      to="/my-flow"
      component={RouterLink}
      sx={{
        cursor: 'pointer'
      }}>
      <img height={24} src="/assets/logos/logo_black.svg" alt="" />
    </Box>
  )
}
