export const listToTree = (list) => {
  var map = {},
    node,
    roots = [],
    i

  for (i = 0; i < list.length; i += 1) {
    map[list[i].id] = i // initialize the map
    list[i].items = [] // initialize the children
  }

  for (i = 0; i < list.length; i += 1) {
    node = list[i]
    if (node.parentId) {
      // if you have dangling branches check that map[node.parentId] exists
      list[map[node.parentId]].items.push(node)
    } else {
      roots.push(node)
    }
  }
  return roots
}

export const customSortName = (nameA, nameB) => {
  return nameA.localeCompare(nameB, undefined, {
    numeric: true,
    sensitivity: 'base'
  })
}
