import { id } from 'date-fns/locale'
import _, { flow } from 'lodash'

import Config from '../config'
import { CURRENT_HUB } from '../constants'
import { httpDelete, httpGet, httpPost } from '../services/http'

const urlMerging = `${Config.DOMAIN_API_MERGING}`
const endPoints = {
  list: '/_api/flow/list',
  create: '/_api/flow/create',
  edit: (id) => `/_api/flow/${id}/edit`,
  delete: '/_api/flow',
  getDetailByColumn: '/_api/flow/get-detail-by-column',
  details: (id) => `/_api/flow/${id}/details`,
  reactivate: (id) => `/_api/flow/${id}/reactivate`
}

export const getFlowListAPI = (_hubId) => {
  const currentHubId = localStorage.getItem(CURRENT_HUB)
  return httpGet(
    `${urlMerging}${endPoints.list}`,
    { smart_hub_id: currentHubId },
    {},
    true
  )
}

export const getFlowDetailAPI = (flowId) => {
  return httpGet(`${urlMerging}${endPoints.details(flowId)}`, {}, {}, true)
}

export const createFlowAPI = (data = {}) => {
  const currentHubId = localStorage.getItem(CURRENT_HUB)
  data.smartHubId = currentHubId
  return httpPost(`${urlMerging}${endPoints.create}`, data, {}, true)
}

export const editFlowNameAPI = (_id, { name = '' }) => {
  const currentHubId = localStorage.getItem(CURRENT_HUB)
  const payload = {
    name: name,
    smartHubId: currentHubId
  }
  return httpPost(`${urlMerging}${endPoints.edit(_id)}`, payload, {}, true)
}

export const updateDataFlowAPI = (
  _id,
  { folderId, data = {}, dataForHub = {}, status = undefined }
) => {
  const currentHubId = localStorage.getItem(CURRENT_HUB)
  const payload = {
    smartHubId: currentHubId
  }

  if (status !== undefined) {
    payload.status = status
  }

  if (!_.isUndefined(folderId)) {
    payload.folderId = folderId
  }

  if (!_.isEmpty(data)) {
    payload.data = data
  }

  console.log('data for hub')
  console.log(dataForHub)

  if (!_.isEmpty(dataForHub)) {
    payload.dataForHub = dataForHub
  }

  return httpPost(`${urlMerging}${endPoints.edit(_id)}`, payload, {}, true)
}

export const deleteFlowAPI = (_id) => {
  return httpDelete(`${urlMerging}${endPoints.delete}/${_id}`, {}, {}, true)
}

export const reactivateFlowAPI = (_id) => {
  return httpGet(`${urlMerging}${endPoints.reactivate(_id)}`, {}, {}, true)
}
