import React from 'react'
import { Navigate, useLoaderData, useRoutes } from 'react-router-dom'

import BaseLayout from './layouts/BaseLayout'

// layouts
/*
import SimpleLayout from './layouts/simple'
import ChooseHubPage from './pages/ChooseHub/ChooseHubPage'
import DashboardCreatePage from './pages/Dashboard/DashboardCreatePage'
import DashboardCustomizePage from './pages/Dashboard/DashboardCustomizePage'
import DashboardPage from './pages/Dashboard/DashboardPage'
import DashboardSharedPage from './pages/Dashboard/DashboardSharedPage'
import DashboardSharedViewPage from './pages/Dashboard/DashboardSharedViewPage'
import DevicePage from './pages/Device/DevicePage'
import DrawFlow from './pages/DrawFlowIntegrate'
import AccountNotFoundPage from './pages/Error/AccountNotFoundPage'
import HubNotConnectionPage from './pages/Error/HubNotConnectionPage'
import FlowPage from './pages/Flow/FlowPage'
import HubPage from './pages/Hub/HubPage'
import HubPageDemo from './pages/Hub/demo/HubPageDemo'
import LabPage from './pages/Lab/LabPage'
import LoginPage from './pages/Login/LoginPage'
import Page404 from './pages/Page404'
import RegisterSmartHubPage from './pages/RegisterSmartHub/RegisterSmartHubPage'
import ScanDevicePage from './pages/ScanDevice/ScanDevicePage'
import MyZonePage from './pages/Zone/ZonePage'
import RegisterSmartHubPageDemo from "./pages/RegisterSmartHub/demo/RegisterSmartHubPageDemo";
import ScanDevicePageDemo from "./pages/ScanDevice/demo/ScanDevicePageDemo";
*/
// ----------------------------------------------------------------------

const LoginPage = React.lazy(() => import('./pages/Login/LoginPage'))
const SimpleLayout = React.lazy(() => import('./layouts/simple'))
const ChooseHubPage = React.lazy(() =>
  import('./pages/ChooseHub/ChooseHubPage')
)
const DashboardCreatePage = React.lazy(() =>
  import('./pages/Dashboard/DashboardCreatePage')
)
const DashboardCustomizePage = React.lazy(() =>
  import('./pages/Dashboard/DashboardCustomizePage')
)
const DashboardPage = React.lazy(() =>
  import('./pages/Dashboard/DashboardPage')
)
const DashboardSharedPage = React.lazy(() =>
  import('./pages/Dashboard/DashboardSharedPage')
)
const DashboardSharedViewPage = React.lazy(() =>
  import('./pages/Dashboard/DashboardSharedViewPage')
)
const DevicePage = React.lazy(() => import('./pages/Device/DevicePage'))

// import DrawFlow from './pages/DrawFlowIntegrate'
const DrawFlow = React.lazy(() => import('./pages/DrawFlowIntegrate'))
const AccountNotFoundPage = React.lazy(() =>
  import('./pages/DrawFlowIntegrate')
)
const HubNotConnectionPage = React.lazy(() =>
  import('./pages/Error/HubNotConnectionPage')
)
const FlowPage = React.lazy(() => import('./pages/Flow/FlowPage'))
const HubPage = React.lazy(() => import('./pages/Hub/HubPage'))
const HubPageDemo = React.lazy(() => import('./pages/Hub/demo/HubPageDemo'))
const LabPage = React.lazy(() => import('./pages/Lab/LabPage'))
const Page404 = React.lazy(() => import('./pages/Page404'))
const RegisterSmartHubPage = React.lazy(() =>
  import('./pages/RegisterSmartHub/RegisterSmartHubPage')
)
const ScanDevicePage = React.lazy(() =>
  import('./pages/ScanDevice/ScanDevicePage')
)
const MyZonePage = React.lazy(() => import('./pages/Zone/ZonePage'))
const RegisterSmartHubPageDemo = React.lazy(() =>
  import('./pages/RegisterSmartHub/demo/RegisterSmartHubPageDemo')
)
const ScanDevicePageDemo = React.lazy(() =>
  import('./pages/ScanDevice/demo/ScanDevicePageDemo')
)

export default function Router() {
  const routes = useRoutes([
    {
      path: 'login',
      element: <LoginPage />
    },
    {
      path: 'drawflow',
      element: <DrawFlow />
    },
    {
      path: 'dashboard/view',
      element: <DashboardSharedPage />
    },
    {
      path: 'dashboard/view/:shortUrl',
      element: <DashboardSharedViewPage />
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/login" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    {
      element: <BaseLayout />,
      children: [
        {
          path: '/dashboard',
          element: <DashboardPage />
        },
        {
          path: '/dashboard/create',
          element: <DashboardCreatePage />
        },
        {
          path: '/dashboard/customize',
          element: <DashboardCustomizePage />
        },
        {
          path: '/account-not-found',
          element: <AccountNotFoundPage />
        },
        {
          path: '/my-flow',
          element: <FlowPage />
        },
        {
          path: '/my-zone',
          element: <MyZonePage />
        },
        {
          path: '/my-hub',
          element: <HubPage />
        },
        {
          path: '/my-device/:deviceId',
          element: <DevicePage />
        },
        {
          path: '/my-lab',
          element: <LabPage />
        },
        {
          path: 'smarthub',
          children: [
            {
              path: 'register',
              element: <RegisterSmartHubPage />
            },
            {
              path: 'not-active',
              element: (
                <HubNotConnectionPage
                  title={'Smart hub is not active'}
                  descriptionHtml={
                    'Not yet activated. <br /> Please turn on your Smart Hub'
                  }
                />
              )
            },
            {
              path: 'not-connection',
              element: (
                <HubNotConnectionPage
                  title={'Account not connected with hub '}
                  descriptionHtml={'Account not <br /> connected with hub'}
                />
              )
            },
            {
              path: 'is-rebooting',
              element: (
                <HubNotConnectionPage
                  title={'smart hub is rebooting'}
                  descriptionHtml={'Smarthub is rebooting'}
                />
              )
            },
            {
              path: 'choose',
              element: <ChooseHubPage />
            }
          ]
        },
        {
          path: 'device',
          children: [
            {
              path: 'scans',
              element: <ScanDevicePage />
            }
          ]
        },
        {
          path: 'demo',
          children: [
            {
              path: 'smarthub',
              children: [
                {
                  path: 'register',
                  element: <RegisterSmartHubPageDemo />
                }
              ]
            },
            {
              path: 'device',
              children: [
                {
                  path: 'scans',
                  element: <ScanDevicePageDemo />
                }
              ]
            },
            {
              path: 'my-hub',
              element: <HubPageDemo />
            }
          ]
        }
      ]
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />
    }
  ])

  return routes
}
