import SvgColor from '../svg-color'

export default function ArrowDownIcon(props) {
  const { sx } = props

  return (
    <SvgColor
      src="/assets/icons/ic_arrow_down.svg"
      sx={{ width: 16, height: 9, ...sx }}
      {...props}
    />
  )
}
