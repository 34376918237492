import Config from '../config'
import { CURRENT_HUB } from '../constants'
import { httpDelete, httpGet, httpPost } from '../services/http'

const urlMerging = `${Config.DOMAIN_API_MERGING}`
const endPoints = {
  list: '/_api/device/list',
  listType: '/_api/device/types',
  listDeviceModel: '/_api/device/device_models',
  details: (id) => `/_api/device/${id}/details`,
  scanPlugins: (id) => `/_api/device/${id}/scan-plugins`,
  installedPlugins: (id) => `/_api/device/${id}/installed-plugins`,
  moveDevicesToZone: '/_api/device/move-to-zone',
  update: (id) => `/_api/device/${id}/update`,
  create: '/_api/device/create',
  delete: (id) => `/_api/device/delete/${id}`,
  mappingData: (id) => `/_api/device/${id}/get-mapping-data`
}

export const getListDeviceAPI = () => {
  const hubId = localStorage.getItem(CURRENT_HUB)
  return httpGet(
    `${urlMerging}${endPoints.list}`,
    { smart_hub_id: hubId },
    {},
    true
  )
}

export const getListDeviceTypeAPI = () => {
  return httpGet(`${urlMerging}${endPoints.listType}`, {}, {}, true)
}

export const getListDeviceModelAPI = (brand_code = '', device_type = '') => {
  return httpGet(
    `${urlMerging}${endPoints.listDeviceModel}`,
    {
      brand_code: brand_code,
      device_type: device_type
    },
    {},
    true
  )
}

export const getDeviceDetailsAPI = (id) => {
  return httpGet(`${urlMerging}${endPoints.details(id)}`, {}, {}, true)
}

export const scanPluginsByDeviceIdAPI = (id) => {
  return httpGet(`${urlMerging}${endPoints.scanPlugins(id)}`, {}, {}, true)
}

export const getInstalledPluginsByDeviceIdAPI = (id, smartHubId) => {
  let hubId = localStorage.getItem(CURRENT_HUB)

  if (smartHubId) {
    hubId = smartHubId
  }

  return httpGet(
    `${urlMerging}${endPoints.installedPlugins(id)}`,
    { smart_hub_id: hubId },
    {},
    true
  )
}

export const moveDeviceToZoneAPI = (ids, zoneId) => {
  return httpPost(
    `${urlMerging}${endPoints.moveDevicesToZone}`,
    {
      zoneId,
      ids
    },
    {},
    true
  )
}

export const updateDeviceApi = (id, data) => {
  return httpPost(`${urlMerging}${endPoints.update(id)}`, data, {}, true)
}

export const createDeviceAPI = (data) => {
  return httpPost(`${urlMerging}${endPoints.create}`, data, {}, true)
}

export const deleteDeviceAPI = (id) => {
  return httpDelete(`${urlMerging}${endPoints.delete(id)}`, {}, {}, true)
}

export const getDeviceMappingDataAPI = (deviceId) => {
  const smartHubId = localStorage.getItem(CURRENT_HUB)
  return httpGet(
    `${urlMerging}${endPoints.mappingData(deviceId)}`,
    {
      smart_hub_id: smartHubId
    },
    {},
    true
  )
}
