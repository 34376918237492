function DemoConfig(RUNTIME_ENV) {
  return {
    'register': {
      'path': '/device/scans'
    },
    'scan_devices': {
      'path': '/smarthub/register'
    },
    'restart': {
      'path': '/my-hub'
    }
  }
}

export default DemoConfig
