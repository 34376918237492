import _ from 'lodash'

import Config from '../config'
import { CURRENT_HUB } from '../constants'
import { httpDelete, httpGet, httpPost } from '../services/http'

const urlMerging = `${Config.DOMAIN_API_MERGING}`
const endPoints = {
  list: '/_api/hub/list',
  listFlow: (_hubId) => `/_api/v1/hub/${_hubId}/list-flow`,
  updateHub: (hubId) => `/_api/hub/${hubId}/update`,
  updateBackground: (hubId) => `/_api/hub/update-background/${hubId}`,
  delete: (hubId) => `/_api/hub/${hubId}`,
  removeSmartHUb: (hubId) => `/_api/hub/remove_smart_hub/${hubId}`,
  commandScan: '/_api/hub/command/scan',
  commandDevice: (smartHubId) =>
    `/_api/hub/command/device?smart_hub_id=${smartHubId}`,
  commandStop: '/_api/hub/command/stop',
  devices: '/_api/hub/devices',
  installPlugin: (id) => `/_api/hub/${id}/plugin/install`,
  uninstallPlugin: (id) => `/_api/hub/${id}/plugin/uninstall`,
  registration: `/_api/hub/registration`,
  auth: `/_api_smarthub/auth`,
  restartSmartHub: (id) => `/_api/hub/${id}/command/reboot`,
  getSmarthubDetail: (id) => `/_api/hub/detail?smart_hub_id=${id}`,
  getActivitiesLog: (id) => `/_api/hub/get-activities-log/${id}`
}

export const getHubList = () => {
  return httpGet(urlMerging + endPoints.list, {}, {}, true)
}

export const restartSmartHubAPI = (id) => {
  return httpGet(urlMerging + endPoints.restartSmartHub(id), {}, {}, true)
}

export const getSmartHubDetailAPI = (smartHubId) => {
  let hubId = localStorage.getItem(CURRENT_HUB)

  if (smartHubId) {
    hubId = smartHubId
  }

  return httpGet(urlMerging + endPoints.getSmarthubDetail(hubId), {}, {}, true)
}

// Todo(api): deprecated
export const getListFlowByHubId = () => {
  const hubId = localStorage.getItem(CURRENT_HUB)
  return httpGet(endPoints.listFlow(hubId))
}

export const updateHubApi = (id, data) => {
  return httpPost(`${urlMerging}${endPoints.updateHub(id)}`, data, {}, true)
}

export const updateBackgroundHubApi = (id, backgroundId) => {
  return httpPost(
    `${urlMerging}${endPoints.updateBackground(id)}`,
    {
      user_image_id: backgroundId
    },
    {},
    true
  )
}

export const deleteHubAPI = (id) => {
  return httpDelete(`${urlMerging}${endPoints.delete(id)}`, {}, {}, true)
}

export const removeSmartHubAPI = () => {
  const id = localStorage.getItem(CURRENT_HUB)
  return httpDelete(
    `${urlMerging}${endPoints.removeSmartHUb(id)}`,
    {},
    {},
    true
  )
}

export const commandScanAPI = (
  { smart_hub_id, scanTime = 20 },
  options = {}
) => {
  return httpGet(
    `${urlMerging}${endPoints.commandScan}`,
    {
      smart_hub_id: smart_hub_id,
      scan_time: scanTime
    },
    {},
    true,
    options
  )
}

export const commandDeviceAPI = (data = {}, options = {}) => {
  const hubId = localStorage.getItem(CURRENT_HUB)

  return httpPost(
    `${urlMerging}${endPoints.commandDevice(hubId)}`,
    data,
    true,
    options
  )
}

export const commandStopAPI = (host, { readerId, scanTime = 20 }) => {
  return httpPost(
    `${urlMerging}${endPoints.commandStop}`,
    {
      reader_id: readerId
    },
    {},
    true
  )
}
export const scanDevicesAPI = ({ smart_hub_id }) => {
  return httpGet(
    `${urlMerging}${endPoints.devices}`,
    {
      smart_hub_id: smart_hub_id
    },
    {},
    true
  )
}

export const installPluginByIdAPI = (pluginId) => {
  const hubId = localStorage.getItem(CURRENT_HUB)
  return httpPost(
    `${urlMerging}${endPoints.installPlugin(hubId)}`,
    { pluginId },
    {},
    true
  )
}

export const uninstallPluginByIdAPI = (pluginId) => {
  const hubId = localStorage.getItem(CURRENT_HUB)
  return httpPost(
    `${urlMerging}${endPoints.uninstallPlugin(hubId)}`,
    { pluginId },
    {},
    true
  )
}

export const registrationHubAPI = (data) => {
  return httpPost(`${urlMerging}${endPoints.registration}`, data, {}, true)
}

export const authHubAPI = (data) => {
  return httpPost(`${urlMerging}${endPoints.auth}`, data, {}, true)
}

export const getActivitiesLogAPI = (data = {}) => {
  let queryString = {}
  let smartHubId = ''
  if (_.get(data, 'smartHubId')) {
    smartHubId = _.get(data, 'smartHubId')
  } else {
    smartHubId = localStorage.getItem(CURRENT_HUB)
  }
  return httpGet(
    `${urlMerging}${endPoints.getActivitiesLog(smartHubId)}`,
    data,
    {},
    true
  )
}
