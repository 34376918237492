//
import HelveticaNeueLTPro from '../../assets/fonts/HelveticaNeueLTPro-Md.woff2'
import HelveticaNeueLTProThin from '../../assets/fonts/HelveticaNeueLTPro-Th.woff2'

// ----------------------------------------------------------------------

const MuiCssBaseline = {
  MuiCssBaseline: {
    styleOverrides: `{
           @font-face {
              font-family: 'Helvetica Neue LT Pro';
              font-weight: 500;
              src: local('Helvetica Neue LT Pro'), url(${HelveticaNeueLTPro}) format('woff2');
            }   
          }
            @font-face {
              font-family: 'Helvetica Neue LT Pro Thin';
              font-weight: 250;
              src: local('Helvetica Neue LT Pro Thin'), url(${HelveticaNeueLTProThin}) format('woff2');
            }   
          }
          `
  }
}

export default function ComponentsOverrides(theme) {
  return Object.assign(MuiCssBaseline)
}
