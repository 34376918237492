import { useAuth0 } from '@auth0/auth0-react'
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  MenuItem,
  Popover,
  Typography
} from '@mui/material'
// @mui
import { alpha } from '@mui/material/styles'
import { useState } from 'react'
import { NavLink as RouterLink } from 'react-router-dom'
import SmartHubIcon from 'src/components/icon/SmartHubIcon'
import _useTranslation from 'src/hooks/_useTranslation'

import config from '../../../../config'
import { AUTH_TOKEN, CURRENT_HUB, TRANS } from '../../../../constants'

export default function AccountPopover() {
  const { logout, user } = useAuth0()
  const { t } = _useTranslation([TRANS.SMARTHUB])

  const [open, setOpen] = useState(null)

  const handleOpen = (event) => {
    setOpen(event.currentTarget)
  }

  const handleClose = () => {
    setOpen(null)
  }

  const onClickLogout = () => {
    localStorage.removeItem(CURRENT_HUB)
    localStorage.removeItem(AUTH_TOKEN)
    logout({
      returnTo: `${config.DOMAIN}${config.REDIRECT_LOGIN}`
    })
  }

  if (!user) {
    return null
  }

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          filter: 'drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.15))',
          borderRadius: '22.5px',
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '22.5px',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8)
            }
          })
        }}>
        <Avatar
          sx={{
            height: 36,
            width: 36
          }}
          src={user.picture}
          alt="photoURL"
        />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 'fit-content',
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75
            }
          }
        }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user.given_name} {user.family_name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />
        <MenuItem
          to="/smarthub/register"
          component={RouterLink}
          onClick={() => {
            setOpen(null)
          }}
          sx={{
            m: 1,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
          <Typography variant="body16">
            {t('register', TRANS.SMARTHUB)}
          </Typography>
          <SmartHubIcon sx={{ width: 24, height: 24 }} />
        </MenuItem>
        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          onClick={onClickLogout}
          sx={{
            m: 1,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
          <Typography variant="body16">{t('logout')}</Typography>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2.15286 1.03896C1.85377 1.03896 1.56694 1.15937 1.35546 1.3737C1.14398 1.58802 1.02517 1.87871 1.02517 2.18182V13.8182C1.02517 14.1213 1.14398 14.412 1.35546 14.6263C1.56694 14.8406 1.85377 14.961 2.15286 14.961H5.94598V16H2.15286C1.58188 16 1.0343 15.7701 0.630557 15.361C0.226818 14.9518 0 14.3968 0 13.8182V2.18182C0 1.60316 0.226818 1.04821 0.630557 0.63904C1.0343 0.229869 1.58188 0 2.15286 0H5.94598V1.03896H2.15286ZM11.1744 3.10946L16 7.99996L11.1744 12.8905L10.4495 12.1558L14.0376 8.51951H4.92057V7.48055H14.0377L10.4495 3.84411L11.1744 3.10946Z"
              fill="#222021"
            />
          </svg>
        </MenuItem>
      </Popover>
    </>
  )
}
