import { atom } from 'recoil'

export const selectedFolderFlowState = atom({
  key: 'selected_folder_flow',
  default: undefined
})

export const selectedFolderFlowDetailState = atom({
  key: 'selected_folder_flow_detail',
  default: {}
})

export const listFolderFlowState = atom({
  key: 'list_folder_flow',
  default: []
})
