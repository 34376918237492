import { setRecoil } from 'recoil-nexus'
import { CURRENT_HUB } from 'src/constants'
import { currentHubState } from 'src/recoil/hub/atoms'

export const resetCurrentHubAction = () => {
  localStorage.removeItem(CURRENT_HUB)
  setRecoil(currentHubState, '')
}

export const setCurrentHubAction = (smartHubId) => {
  localStorage.setItem(CURRENT_HUB, smartHubId)
  setRecoil(currentHubState, smartHubId)
}
