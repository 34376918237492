import { io } from 'socket.io-client'

import config from '../config'
import { AUTH_TOKEN } from '../constants'

// "undefined" means the URL will be computed from the `window.location` object
const URL = config.SOCKET_URL

export const connectSocket = () => {
  const token = localStorage.getItem(AUTH_TOKEN)

  const socket = io(URL, {
    // path: '/wss',
    auth: (cb) => {
      cb({ token: token })
    }
  })

  return socket
}
