import axios from 'axios'
import _ from 'lodash'
import HttpStatusCode from 'src/constants/HttpStatusCode'

import Config from '../config/index'
import { AUTH_TOKEN } from '../constants'

export const request = (
  endPoint,
  payload = {},
  method = 'get',
  headers = {},
  withoutDomain = false,
  options = {}
) => {
  let url = `${Config.DOMAIN_API}${endPoint}`
  if (withoutDomain) {
    url = endPoint
  }

  let headerSend = {
    ...headers
  }

  const token = localStorage.getItem(AUTH_TOKEN)

  if (token) {
    if (!headerSend['Authorization']) {
      headerSend = {
        Authorization: `Bearer ${token}`,
        ...headerSend
      }
    }
  }

  if (method === 'get') {
    payload = {
      params: payload
    }
  } else {
    payload = {
      data: payload
    }
  }

  return axios({
    method,
    url,
    headers: headerSend,
    ...payload,
    ...options
  })
    .then((res) => {
      const data = _.get(res, 'data')
      return data
    })
    .catch((error) => {
      if (_.get(error, 'code') === 'ERR_NETWORK') {
        return {
          statusCode: HttpStatusCode.InternalServerError,
          message: 'ERR_NETWORK'
        }
      }
      return _.get(error, 'response.data')
    })
}

export const httpGet = (
  endPoint,
  payload = {},
  headers = {},
  withoutDomain = false
) => {
  return request(endPoint, payload, 'get', headers, withoutDomain)
}

export const httpPost = (
  endPoint,
  payload = {},
  headers = {},
  withoutDomain = false,
  options = {}
) => {
  return request(endPoint, payload, 'post', headers, withoutDomain, options)
}

export const httpDelete = (
  endPoint,
  payload = {},
  headers = {},
  withoutDomain = false
) => {
  return request(endPoint, payload, 'delete', headers, withoutDomain)
}
