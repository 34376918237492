import {
  Box,
  Divider,
  ListItemButton,
  ListItemIcon,
  Slide
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { NavLink as RouterLink, useLocation } from 'react-router-dom'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import TriangleIcon from '../../../components/icon/navbar/TriangleIcon'
import { HIDE_IN_PATH } from '../../../constants'
import { hasChangePageState } from '../../../recoil/common/commonState'
import { hasChangeFlowDetail } from '../../../recoil/flow/flowState'

const StyledNavbarItemIcon = styled(ListItemIcon)(({ theme }) => ({
  width: 48,
  height: 48,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.common.white
}))

export const StyledNavbarItem = styled((props) => (
  <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius
}))

export default function NavbarItem({ item }) {
  const { title, path, icon, divider } = item

  const location = useLocation()

  const hasChangeFlowDetailValue = useRecoilValue(hasChangeFlowDetail)
  const setHasChangePage = useSetRecoilState(hasChangePageState)

  const disabledNavItem =
    item.disabled || HIDE_IN_PATH.includes(location.pathname)

  const onClick = (e) => {
    if (hasChangeFlowDetailValue) {
      e.preventDefault()

      setHasChangePage((prev) => {
        return {
          value: prev.value + 1,
          path
        }
      })
      return
    }
  }

  return (
    <>
      <StyledNavbarItem
        disabled={disabledNavItem}
        component={RouterLink}
        to={path}
        onClick={onClick}
        sx={{
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '&.Mui-disabled': {
            '& .MuiListItemIcon-root .svg-color': {
              opacity: 0.5,
              backgroundColor: 'white'
            }
          },
          '&.active': {
            color: 'text.primary',
            backgroundColor: 'white',

            '& .MuiListItemIcon-root .svg-color': {
              backgroundColor: 'background.black'
            }
          },
          '&:hover': {
            backgroundColor: 'white',
            '& .MuiListItemIcon-root': {
              color: 'common.black',
              '& .svg-color': {
                backgroundColor: 'background.black'
              }
            }
          }
        }}>
        {location.pathname.includes(path) && (
          <Slide direction="down" in={true} mountOnEnter unmountOnExit>
            <Box
              sx={{
                position: 'fixed',
                mt: '7px',
                left: '60px',
                cursor: 'default'
              }}>
              <TriangleIcon />
            </Box>
          </Slide>
        )}
        <StyledNavbarItemIcon>{icon ? icon : title}</StyledNavbarItemIcon>
      </StyledNavbarItem>
      {divider && <Divider sx={{ borderColor: 'common.darkGrey' }} />}
    </>
  )
}
