import appConfig from './app.config'
import authConfig from './auth.config'
import navConfig from './nav.config'

let RUNTIME_ENV = process.env

if (
  typeof window !== 'undefined' &&
  typeof window.__RUNTIME_CONFIG__ !== 'undefined'
) {
  RUNTIME_ENV = window.__RUNTIME_CONFIG__
}

const config = {
  ...appConfig(RUNTIME_ENV),
  ...authConfig(RUNTIME_ENV),
  ...navConfig(RUNTIME_ENV)
}

export default config
