import _ from 'lodash'
import { atom, selector } from 'recoil'

import { listFolderFlowState } from '../folder-flow/folderFlowState'
import { listFlowState } from './listFlowState'

export const latestFlowState = atom({
  key: 'latest_flow',
  default: undefined
})

export const selectedFlow = atom({
  key: 'selected_flow',
  default: undefined
})

export const currentFlowState = atom({
  key: 'current_flow',
  default: undefined
})
export const showPopupAddCard = atom({
  key: 'show_popup_add_card',
  default: false
})

export const hasChangeFlowDetail = atom({
  key: 'has_change_flow_detail',
  default: false
})

export const replaceCardState = atom({
  key: 'replace-card',
  default: {}
})

export const selectedFlowSelector = selector({
  key: 'selected_flow_selector',
  get: ({ get }) => {
    return get(selectedFlow)
  },
  set: ({ get, set, reset }, id) => {
    const currentId = get(selectedFlow)
    if (currentId !== id) {
      set(selectedFlow, id)
    }
  }
})

export const setPopupAddCard = selector({
  key: 'show_popup_add_card_selector',
  get: ({ get }) => {
    return get(showPopupAddCard)
  },
  set: ({ get, set, reset }, isShow) => {
    const current = get(showPopupAddCard)
    if (current === isShow) {
      return
    }
    set(showPopupAddCard, isShow)
  }
})

export const searchFlowState = atom({
  key: 'search_flow_atom',
  default: ''
})

export const filterFolderFlowListState = selector({
  key: 'filter_flow_folder_selector',
  get: ({ get }) => {
    const value = get(searchFlowState)
    const flowList = get(listFlowState)
    const folderList = get(listFolderFlowState)

    const searchValue = _.toLower(_.trim(value))

    if (searchValue !== '') {
      let regexSearch = new RegExp(searchValue, 'i')

      const flowListFilter = _.filter(flowList, (flow) =>
        regexSearch.test(flow.name)
      )

      const folderListFilter = folderList
        .map((folder) => {
          let flows = _.filter(folder.flows, (flow) =>
            regexSearch.test(flow.name)
          )

          if (_.isEmpty(flows) && regexSearch.test(folder.name)) {
            return {
              ...folder,
              flows,
              hasFlow: false
            }
          }

          return {
            ...folder,
            flows
          }
        })
        .filter(
          (folder) => !_.isEmpty(folder.flows) || regexSearch.test(folder.name)
        )

      return {
        flows: flowListFilter,
        folders: folderListFilter
      }
    }

    return {
      flows: flowList,
      folders: folderList
    }
  }
})

export const flowDataState = atom({
  key: 'flow_data_atom',
  default: []
})

export const flowHistoryState = atom({
  key: 'flow_history_atom',
  default: []
})

export const flowHistoryUndoneState = atom({
  key: 'flow_history_undone_atom',
  default: []
})
