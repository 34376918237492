import { Box, Drawer, List } from '@mui/material'

import Scrollbar from '../../../components/scrollbar/Scrollbar'
import config from '../../../config'
import NavbarItem from './NavbarItem'

export default function Navbar() {
  const NAV_WIDTH = 64

  return (
    <Box
      component="nav"
      sx={{
        width: NAV_WIDTH
      }}>
      <Drawer
        open
        variant="permanent"
        PaperProps={{
          sx: {
            width: NAV_WIDTH,
            bgcolor: 'background.default',
            borderRightStyle: 'dashed'
          }
        }}>
        <Scrollbar
          sx={{
            backgroundColor: 'background.black',
            height: 1,
            '& .simplebar-content': {
              height: 1,
              display: 'flex',
              flexDirection: 'column'
            }
          }}>
          <Box sx={{ mt: '72px' }}>
            <List
              disablePadding
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                p: 0,
                px: 1
              }}>
              {config.NAVBAR.map((item) => (
                <NavbarItem key={item.title} item={item} />
              ))}
            </List>
          </Box>
        </Scrollbar>
      </Drawer>
    </Box>
  )
}
