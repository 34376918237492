import { Auth0Provider } from '@auth0/auth0-react'
import { SnackbarProvider } from 'notistack'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import RecoilNexus from 'recoil-nexus'

//
import App from './App'
import config from './config'
import './library/i18n'
import reportWebVitals from './reportWebVitals'
import * as serviceWorker from './serviceWorker'

// ----------------------------------------------------------------------

const providerOptions = {
  domain: config.AUTH0_DOMAIN,
  clientId: config.AUTH0_CLIENT_ID,
  ...(config.AUTH0_AUDIENCE ? { audience: config.AUTH0_AUDIENCE } : null),
  redirectUri: `${config.DOMAIN}${config.REDIRECT_LOGIN}`
}

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <Auth0Provider {...providerOptions}>
    <HelmetProvider>
      <BrowserRouter>
        <RecoilRoot>
          <RecoilNexus />
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <App />
          </SnackbarProvider>
        </RecoilRoot>
      </BrowserRouter>
    </HelmetProvider>
  </Auth0Provider>
)

// If you want to enable client cache, register instead.
serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
