const SmartHubStatus = {
  ACTIVE: 'active',
  REGISTERED: 'registered',
  REBOOTING: 'rebooting'
}

export const SmartHubStatusMapping = {
  [SmartHubStatus.ACTIVE]: 'active',
  [SmartHubStatus.REGISTERED]: 'inactive',
  [SmartHubStatus.REBOOTING]: 'Restarting'
}

export default SmartHubStatus
