import { atom } from 'recoil'

import { CURRENT_HUB } from '../../constants'

export const currentHubState = atom({
  key: 'CurrentHub',
  default: localStorage.getItem(CURRENT_HUB)
})

export const currentHubDetailState = atom({
  key: 'CurrentHubDetail',
  default: {}
})

export const hubListState = atom({
  key: 'HubList_Atom',
  default: []
})
