import { Select as MUISelect } from '@mui/material'
import * as _ from 'lodash'
import { forwardRef } from 'react'

import ArrowDownIcon from '../../icon/ArrowDownIcon'

const Select = forwardRef((props, ref) => {
  const _defaultProps = {
    sx: {
      height: 36,
      borderRadius: 'unset',
      fontFamily: 'inherit',
      backgroundColor: 'background.white',
      '& .MuiSelect-icon': {
        top: 'calc(50% - 4.5px)'
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'common.darkGrey'
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'common.black'
      }
    }
  }

  const { children, ...restProps } = _.merge(_defaultProps, props)

  return (
    <MUISelect ref={ref} IconComponent={ArrowDownIcon} {...restProps}>
      {children}
    </MUISelect>
  )
})

export default Select
