import { SvgIcon } from '@mui/material'

export default function TriangleIcon(props) {
  const { sx } = props

  return (
    <SvgIcon
      {...props}
      viewBox="0 0 16 38"
      sx={{ width: 16, height: 38, ...sx }}>
      <path
        d="M3 1.68154V0H0V38H3V36.3185C3 32.3293 4.83154 28.5609 7.96832 26.0963L14.9985 20.5726C16.0176 19.7719 16.0176 18.2281 14.9985 17.4274L7.96832 11.9037C4.83155 9.43907 3 5.67073 3 1.68154Z"
        fill="#222021"
      />
    </SvgIcon>
  )
}
