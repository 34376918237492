const PageUrl = {
    SMARTHUB_NOT_ACTIVE: '/smarthub/not-active',
    SMARTHUB_WAITING_REBOOT: '/smarthub-waiting-reboot',
    SMARTHUB_REGISTRATION: '/smarthub/register',
    SMARTHUB_SELECTION: '/smarthub/choose',
    FLOW: '/my-flow',
    FLOW_DETAILS: (id) => `/my-flow?id=${id}`,
    HUB: '/my-hub',
    SCAN_DEVICE: '/device/scans',
    DEVICE_DETAILS: (id) => `/my-device/${id}`,
    ZONE: '/my-zone',
    NOT_FOUND: '/404',
    SMARTHUB_DEMO: (link) => `/demo${link}`
}

export default PageUrl
